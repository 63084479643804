<template>
    <main>
		<HeaderTab 
            :title="$t('global.resultats')"
        />
        <div class="container-fluid">
            <div id="content">
                <Results />
            </div>
        </div>
	</main>
</template>


<script type="text/javascript">
	export default {
		name: "feed",
		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			Results: () => import('@/components/Dedicated/Acts/Results')
		}
	}
</script>
